import {Descriptions, Drawer} from "antd";
import {useDispatch, useSelector} from "react-redux";
import global from "../../global";
import QRCode from 'qrcode.react';

const ExamApplyInfo = () => {
  const examApply = useSelector((state: any) => state.examApply)
  const dispatch = useDispatch()


  return (
    <>
      <Drawer
        title="查看报名详情"
        width={500}
        open={examApply.openInfo}
        onClose={() => dispatch.examApply.setData({openInfo: false})}
        bodyStyle={{paddingBottom: 80}}
      >

        <Descriptions title="" column={1}>
          <Descriptions.Item label={"录入成绩二维码"}>
            <QRCode
              value={'https://wemp.taoyun.art/examQrCode?applyId=' + examApply.info?.id + '&idCardNumber=' + examApply.info?.idCardNumber}  //生成二维码的内容
              size={128} //二维码尺寸
              fgColor="#000000"  //二维码颜色
            />
          </Descriptions.Item>
          <Descriptions.Item label="编号">{examApply.info?.id}</Descriptions.Item>
          <Descriptions.Item label="考点">{examApply.info?.companyName}</Descriptions.Item>
          <Descriptions.Item label="专业">{examApply.info?.specialityName}</Descriptions.Item>
          <Descriptions.Item label="等级">{examApply.info?.level}</Descriptions.Item>
          <Descriptions.Item label="考试日期">{examApply.info?.auditDate}</Descriptions.Item>
          <Descriptions.Item label="姓名">{examApply.info?.idCardName}</Descriptions.Item>
          <Descriptions.Item label="姓名拼音">{examApply.info?.namePinyin}</Descriptions.Item>
          <Descriptions.Item label="证件类型">{examApply.info?.idCardType}</Descriptions.Item>
          <Descriptions.Item label="证件号码">{examApply.info?.idCardNumber}</Descriptions.Item>
          <Descriptions.Item label="出生日期">{examApply.info?.birthday}</Descriptions.Item>
          <Descriptions.Item label="性别">{examApply.info?.sex}</Descriptions.Item>
          <Descriptions.Item label="国籍">{examApply.info?.country}</Descriptions.Item>
          <Descriptions.Item label="民族">{examApply.info?.nation}</Descriptions.Item>
          <Descriptions.Item label="地区">{examApply.info?.area}</Descriptions.Item>
          <Descriptions.Item label="联系地址">{examApply.info?.address}</Descriptions.Item>
          <Descriptions.Item label="照片">
            {
              examApply.info.photoFile &&
              <img src={global.getFileOpenUrl(examApply.info.photoFile)} alt="" style={{width: 150}}/>
            }
          </Descriptions.Item>
          <Descriptions.Item label="机构名称">{examApply.info?.orgName}</Descriptions.Item>
          <Descriptions.Item label="指导老师">{examApply.info?.teacher}</Descriptions.Item>
          <Descriptions.Item label="老师电话">{examApply.info?.teacherMobile}</Descriptions.Item>
          <Descriptions.Item label="家长姓名">{examApply.info?.parents}</Descriptions.Item>
          <Descriptions.Item label="家长电话">{examApply.info?.mobile}</Descriptions.Item>
          <Descriptions.Item label="报名状态">{examApply.info?.applyStatusName}</Descriptions.Item>
          <Descriptions.Item label="信息来源">{examApply.info?.sourceTypeName}</Descriptions.Item>
          <Descriptions.Item label="备注">{examApply.info?.memo}</Descriptions.Item>
          <Descriptions.Item label="报名时间">{examApply.info?.created}</Descriptions.Item>
        </Descriptions>
      </Drawer>
    </>
  )
}

export default ExamApplyInfo